import React from "react";
import { Link } from "gatsby";

import SecondaryLanding from "../../../layouts/secondary-landing";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import ContactInfo from "../../../components/contact-info/contact-info";
import Icon from "../../../components/custom-widgets/icon";

const AccountDetails = () => {
  const breadcrumbData = [
    {
      id: 1,
      active: true,
      title: "Account Details"
    }
  ];

  const SEOData = {
    title: "Account Details",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: "Account Details"
      },
      {
        name: "description",
        content:
          "WaFd Bank is committed to the privacy and security of our customers. Find all of the important account term information and disclosures here."
      },
      {
        property: "og:title",
        content: "Account Details"
      },
      {
        property: "og:description",
        content:
          "WaFd Bank is committed to the privacy and security of our customers. Find all of the important account term information and disclosures here."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/customer-service/account-details"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      }
    ]
  };

  const IconSelector = ({ type = "internal" }) => {
    if (type === "pdf") {
      return <Icon name="file-alt" class="ml-2" />;
    }
    if (type === "external") {
      return <Icon name="external-link" lib="far" class="ml-2" />;
    }

    return <Icon name="arrow-right" lib="far" class="ml-2" />;
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />

      <section className="container">
        <h1>Account Details</h1>

        <p>
          Thank you for being a valued client of WaFd Bank. Here's where you'll find account terms and disclosures, our
          privacy notice, our current schedule of fees, and other important details about our products and services.
        </p>
        <h2>Personal</h2>
        <p>
          <Link to="/banking-privacy-security" id="banking-privacy-security-page-link" className="text-decoration-none">
            Privacy and Security
            <IconSelector />
          </Link>
          <br />
          <a
            title="Schedule of Consumer Fees Service Charges"
            href="/documents/schedule-of-consumer-fees-service-charges-effective.pdf"
            target="_blank"
            id="schedule-of-consumer-fees-service-charges-pdf"
            className="text-decoration-none"
          >
            Schedule of Consumer Fees Service Charges
            <IconSelector type="pdf" />
          </a>
          <br />
          <a
            title="Personal Deposit Account Agreement and Disclosures"
            href="/documents/wafdbank-consumer-disclosure-guide.pdf"
            target="_blank"
            id="personal-deposit-account-agreement-and-disclosures-pdf"
            className="text-decoration-none"
          >
            Personal Deposit Account Agreement and Disclosures
            <IconSelector type="pdf" />
          </a>
          <br />
          <a
            title="Online Account Funding Process"
            href="/documents/online-account-funding-process.pdf"
            target="_blank"
            id="online-account-funding-process-pdf"
            className="text-decoration-none"
          >
            Online Account Funding Process
            <IconSelector type="pdf" />
          </a>
        </p>

        <h2>Commercial and Small Business</h2>
        <p>
          <a
            title="Schedule of Business Fees Service Charges"
            href="/documents/schedule-of-business-fees-service-charges.pdf"
            target="_blank"
            id="schedule-of-business-fees-service-charges-pdf"
            className="text-decoration-none"
          >
            Schedule of Business Fees Service Charges
            <IconSelector type="pdf" />
          </a>
          <br />
          <a
            title="Business Deposit Account Agreement and Disclosures"
            href="/documents/wafdbank-business-disclosure-guide.pdf"
            target="_blank"
            id="business-disclosure-guide-pdf"
            className="text-decoration-none"
          >
            Business Deposit Account Agreement and Disclosures
            <IconSelector type="pdf" />
          </a>
        </p>

        <h2>Online and Mobile Banking Service Agreements</h2>
        <p>
          <a
            title="Online and Mobile Banking Service Agreement"
            href="/documents/online-and-mobile-banking-service-agreement.pdf"
            target="_blank"
            id="online-and-mobile-banking-service-agreement-pdf"
            className="text-decoration-none"
          >
            Online and Mobile Banking Service Agreement*
            <IconSelector type="pdf" />
          </a>
        </p>

        <h2>Treasury Management Service Agreement</h2>
        <p>
          <a
            title="Treasury Management Terms and Conditions"
            href="/documents/treasury-management-terms-and-conditions.pdf"
            target="_blank"
            id="treasury-management-terms-and-conditions-pdf"
            className="text-decoration-none"
          >
            Treasury Management Terms and Conditions
            <IconSelector type="pdf" />
          </a>
        </p>

        <h2>FEMA Flood Insurance</h2>
        <p>
          <a
            href="https://www.fema.gov/write-your-own-wyo-program"
            rel="noopener noreferrer"
            target="_blank"
            id="fema-write-your-own-wyo-program-link"
            className="text-decoration-none"
          >
            FEMA: What Is The Write Your Own Program?
            <IconSelector type="external" />
          </a>
          <br />
          <a
            href="https://agents.floodsmart.gov/write-policy/resources"
            rel="noopener noreferrer"
            target="_blank"
            id="fema-write-policy-resources-link"
            className="text-decoration-none"
          >
            FEMA: Write Your Own Flood Insurance Company List
            <IconSelector type="external" />
          </a>
        </p>

        <p>
          <em>
            If your business signs into WaFd Bank's online banking using the Treasury Prime or Treasury Express portal,
            then please select "Washington Federal&mdash;Treasury" when adding your accounts to QuickBooks.
          </em>
        </p>

        <p>
          *
          <em>
            For more information about Bill Pay/CheckFree functionality, please visit our{" "}
            <Link to="/personal-banking/online-banking" id="online-banking-page-link">
              Mobile &amp; Online Banking page
            </Link>
            .
          </em>
        </p>
      </section>

      <ContactInfo />
    </SecondaryLanding>
  );
};

export default AccountDetails;
